@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  height: calc(100%);
  width: 100%;
  position: fixed;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

body {
  touch-action: none;
}
